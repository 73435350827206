export const getServiceDelay = (i) => {
  switch(i){
    case 0:
      return "450"
    case 1:
      return "300"
    case 2:
      return "150"
    default: 
      return "0"
  }
}
