import React from "react"
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from 'gatsby'
import { getServiceDelay } from '../utils/transitions'
import Transitions from '../components/transitions'

const Front = ({ content, projects, blogs }) => {
  if (!content) return null
  return (
    <>
      <SEO 
        image={content.seo_image.url+"&w=1200"}
        title={RichText.asText(content.seo_title)}
        keywords={RichText.asText(content.seo_keywords)}
        description={RichText.asText(content.seo_description)}
        url="https://bonsai.is"
      />
      <div className="full_banner home_banner d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-11">
              <Transitions.Word
                str={RichText.asText(content.title)}
              />
              <div class="progress">
                <div class="progress-bar" style={{width: "90%"}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="information_area">
        <div className="container">
          <div className="information_inner">
            <div className="information_text">
              <Transitions.SlideUp>
                <p>{RichText.asText(content.about)}</p>
              </Transitions.SlideUp>
            </div>
            <div className="text-right">
              <Link to="/about" className="btn p-0 btn-link"><img src="../../arrow.svg" className="arrow-left" alt="arrow"/> meet the team</Link>
            </div>
          </div>
        </div>
      </div>

      <div className="projects_list">
        <div className="container">
          {projects.slice(0,3).map((project) => {
            return(
                <div className="project">
                  <div className="project_image">
                    <Link to={"/successes/"+project.node._meta.uid}><Img fluid={project.node.front_imageSharp.childImageSharp.fluid} alt={project.node.front_image.alt}/></Link>
                  </div>
                  <div className="project_info align-items-center d-flex flex-wrap flex-md-nowrap">
                    <h2>{RichText.asText(project.node.title)}</h2>
                    <Link to={"/successes/"+project.node._meta.uid} className="btn p-0 ml-auto btn-link"><img src="../../arrow.svg" className="arrow-left" alt="arrow"/> read study</Link>
                  </div>
                  <ul className="project_services d-none d-md-flex align-items-center list-unstyled">
                    {project.node.services.map((service, index) => (
                      <li className='side-text'>
                        <Transitions.SlideUp
                          delay={getServiceDelay(index)}
                          duration={"250"}
                        >
                          {service.text}
                        </Transitions.SlideUp>
                      </li>
                    ))}
                  </ul>
                </div>
            )
          })}
        </div>
      </div>
      <div className="home_blog">
        <div className="container">
          <div className="home_blog_inner">
            <h2>the blog</h2>
            <ul className="list-unstyled">
              {blogs.slice(0,3).map((post) => {
                return(
                  <li><Link to={"/blog/" + post.node._meta.uid} className="p-0 ml-auto btn-link">{RichText.asText(post.node.title)}</Link></li>
                )
              })}
            </ul>
            <div className="d-flex mt-4">
              <Link to="/blog/" className="btn p-0 ml-auto btn-link"><img src="../../arrow.svg" className="arrow-left" alt="arrow"/> all blogs</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ({ data }) => {
  const content = data.prismic.allHomes.edges[0].node
  const projects = data.prismic.allProjects.edges
  const blogs = data.prismic.allPosts.edges
  if (!content) return null
  return (
    <Layout>
      <Front content={content} projects={projects} blogs={blogs}/>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allHomes {
        edges {
          node {
            seo_image
            about
            title
            seo_title
            seo_keywords
            seo_description
          }
        }
      }
      allProjects(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            date
            title
            services
            front_image
            front_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      allPosts(sortBy: date_DESC) {
        edges {
          node {
            _meta {
              uid
            }
            date
            title
          }
        }
      }
    }
  }
`